// components
import { BrandingWatermark, Category, Chat, ContactMail, EventNote, Tag } from '@mui/icons-material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const NavConfig = () => {
  const { user } = useAuth();
  console.log(user);
  if (user.isSuperAdmin) {
    return [
      // GENERAL
      // ----------------------------------------------------------------------
      {
        items: [
          { title: 'Subadmin', path: PATH_DASHBOARD.subadmin.list, icon: ICONS.dashboard },
          { title: 'Seller', path: PATH_DASHBOARD.seller.list, icon: ICONS.analytics },
          { title: 'User', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
          { title: 'Category', path: PATH_DASHBOARD.category.list, icon: <Category /> },
          { title: 'New Arrivals', path: PATH_DASHBOARD.newArrivals.list, icon: <Category /> },
          { title: 'Brands', path: PATH_DASHBOARD.brands.list, icon: <BrandingWatermark /> },
          { title: 'Tags', path: PATH_DASHBOARD.tags.list, icon: <Tag /> },
          { title: 'Chats', path: PATH_DASHBOARD.chats.list, icon: <Chat /> },

          { title: 'Package', path: PATH_DASHBOARD.package.list, icon: <EventNote /> },
          { title: 'Contact', path: PATH_DASHBOARD.contact.list, icon: <ContactMail /> },
          { title: 'All Product', path: PATH_DASHBOARD.products.list, icon: ICONS.ecommerce },
        ],
      },
    ];
  }

  return [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      items: [
        { title: 'Seller', path: PATH_DASHBOARD.seller.list, icon: ICONS.analytics },
        { title: 'User', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
        { title: 'Category', path: PATH_DASHBOARD.category.list, icon: ICONS.user },
        { title: 'Brands', path: PATH_DASHBOARD.brands.list, icon: ICONS.user },
        { title: 'Package', path: PATH_DASHBOARD.package.list, icon: ICONS.user },
      ],
    },
  ];
};

export default NavConfig;

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, useNavigate } from 'react-router-dom';
// layouts
import useAuth from '../hooks/useAuth';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  let navigateTo = '';
  if (user?.isSuperAdmin === true) {
    navigateTo = PATH_DASHBOARD.subadmin.list;
  } else {
    navigateTo = PATH_DASHBOARD.seller.list;
  }

  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={navigateTo} replace />, index: true },

        {
          path: 'subadmin',
          children: [
            { element: <Navigate to="/dashboard/subadmin/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin']}>
                  <SubAdminList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin']}>
                  {' '}
                  <SubAdminCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:id',
              element: (
                <RoleBasedGuard accessibleRoles={['superadmin']}>
                  {' '}
                  <SubAdminCreate />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'seller',
          children: [
            { element: <Navigate to="/dashboard/seller/list" replace />, index: true },
            { path: 'list', element: <SellerList /> },
            { path: 'create', element: <SellerCreate /> },
            { path: 'edit/:id', element: <SellerCreate /> },
            { path: 'products/:id', element: <ProductList /> },
            { path: 'product/:id', element: <ProductDetails /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'create', element: <UserCreate /> },
            { path: 'edit/:id', element: <UserCreate /> },
            { path: 'chats/:userId', element: <ChatUserList /> },
            { path: 'message/:userId/:receiverId', element: <Messages /> },
          ],
        },
        {
          path: 'package',
          children: [
            { element: <Navigate to="/dashboard/package/list" replace />, index: true },
            { path: 'list', element: <PackageList /> },
            { path: 'create', element: <PackageCreate /> },
            { path: 'edit/:id', element: <PackageCreate /> },
          ],
        },
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <CategoryList /> },
            { path: 'create', element: <CategoryCreate /> },
            { path: 'edit/:id', element: <CategoryCreate /> },
          ],
        },
        {
          path: 'newArrivals',
          children: [
            { element: <Navigate to="/dashboard/newArrivals/list" replace />, index: true },
            { path: 'list', element: <NewArrivalsList /> },
            { path: 'create', element: <NewArrivalsCreate /> },
            { path: 'edit/:id', element: <NewArrivalsCreate /> },
          ],
        },

        {
          path: 'brands',
          children: [
            { element: <Navigate to="/dashboard/brands/list" replace />, index: true },
            { path: 'list', element: <BrandsList /> },
            { path: 'create', element: <BrandsCreate /> },
            { path: 'edit/:id', element: <BrandsCreate /> },
          ],
        },
        {
          path: 'tags',
          children: [
            { element: <Navigate to="/dashboard/tags/list" replace />, index: true },
            { path: 'list', element: <TagsList /> },
            { path: 'create', element: <TagsCreate /> },
            { path: 'edit/:id', element: <TagsCreate /> },
          ],
        },
        {
          path: 'chats',
          children: [
            { element: <Navigate to="/dashboard/chats/list" replace />, index: true },
            { path: 'list', element: <ChatsList /> },
          ],
        },
        {
          path: 'contact',
          children: [
            { element: <Navigate to="/dashboard/contact/list" replace />, index: true },
            { path: 'list', element: <ContactList /> },
          ],
        },
        {
          path: 'products',
          children: [
            { element: <Navigate to="/dashboard/products/list" replace />, index: true },
            { path: 'list', element: <ProductList /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// SuperADmin

// Subadmin
const SubAdminList = Loadable(lazy(() => import('../pages/SuperAdmin/Subadmin/List')));
const SubAdminCreate = Loadable(lazy(() => import('../pages/SuperAdmin/Subadmin/Create')));

// seller
const SellerList = Loadable(lazy(() => import('../pages/SuperAdmin/Seller/List')));
const SellerCreate = Loadable(lazy(() => import('../pages/SuperAdmin/Seller/Create')));

// products
const ProductList = Loadable(lazy(() => import('../pages/SuperAdmin/products/List')));
const ProductDetails = Loadable(lazy(() => import('../pages/SuperAdmin/products/Details')));

// user
const UserList = Loadable(lazy(() => import('../pages/SuperAdmin/User/List')));
const UserCreate = Loadable(lazy(() => import('../pages/SuperAdmin/User/Create')));
const ChatUserList = Loadable(lazy(() => import('../pages/SuperAdmin/chatMonitors/ChatUsers')));
const Messages = Loadable(lazy(() => import('../pages/SuperAdmin/chatMonitors/messages')));

const PackageList = Loadable(lazy(() => import('../pages/SuperAdmin/Package/List')));
const PackageCreate = Loadable(lazy(() => import('../pages/SuperAdmin/Package/Create')));

// category
const CategoryList = Loadable(lazy(() => import('../pages/SuperAdmin/category/List')));
const CategoryCreate = Loadable(lazy(() => import('../pages/SuperAdmin/category/Create')));
// new arrivals
const NewArrivalsList = Loadable(lazy(() => import('../pages/SuperAdmin/newArrivals/List')));
const NewArrivalsCreate = Loadable(lazy(() => import('../pages/SuperAdmin/newArrivals/Create')));
// brands
const BrandsList = Loadable(lazy(() => import('../pages/SuperAdmin/brands/List')));
const BrandsCreate = Loadable(lazy(() => import('../pages/SuperAdmin/brands/Create')));
// tags
const TagsList = Loadable(lazy(() => import('../pages/SuperAdmin/tags/List')));
const TagsCreate = Loadable(lazy(() => import('../pages/SuperAdmin/tags/Create')));
// chats
const ChatsList = Loadable(lazy(() => import('../pages/SuperAdmin/chats/List')));
// contact
const ContactList = Loadable(lazy(() => import('../pages/SuperAdmin/Contact/List')));

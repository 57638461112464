// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  subadmin: {
    create: path(ROOTS_DASHBOARD, '/subadmin/create'),
    list: path(ROOTS_DASHBOARD, '/subadmin/list'),
    edit: path(ROOTS_DASHBOARD, '/subadmin/edit'),
  },
  seller: {
    create: path(ROOTS_DASHBOARD, '/seller/create'),
    list: path(ROOTS_DASHBOARD, '/seller/list'),
    edit: path(ROOTS_DASHBOARD, '/seller/edit'),
  },
  user: {
    create: path(ROOTS_DASHBOARD, '/user/create'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    edit: path(ROOTS_DASHBOARD, '/user/edit'),
    chats: path(ROOTS_DASHBOARD, '/user/chats'),
    messages: path(ROOTS_DASHBOARD, '/user/message'),
  },
  package: {
    create: path(ROOTS_DASHBOARD, '/package/create'),
    list: path(ROOTS_DASHBOARD, '/package/list'),
    edit: path(ROOTS_DASHBOARD, '/package/edit'),
  },
  category: {
    create: path(ROOTS_DASHBOARD, '/category/create'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    edit: path(ROOTS_DASHBOARD, '/category/edit'),
  },
  newArrivals: {
    create: path(ROOTS_DASHBOARD, '/newArrivals/create'),
    list: path(ROOTS_DASHBOARD, '/newArrivals/list'),
    edit: path(ROOTS_DASHBOARD, '/newArrivals/edit'),
  },
  brands: {
    create: path(ROOTS_DASHBOARD, '/brands/create'),
    list: path(ROOTS_DASHBOARD, '/brands/list'),
    edit: path(ROOTS_DASHBOARD, '/brands/edit'),
  },
  tags: {
    create: path(ROOTS_DASHBOARD, '/tags/create'),
    list: path(ROOTS_DASHBOARD, '/tags/list'),
    edit: path(ROOTS_DASHBOARD, '/tags/edit'),
  },
  chats: {
    list: path(ROOTS_DASHBOARD, '/chats/list'),
  },
  contact: {
    list: path(ROOTS_DASHBOARD, '/contact/list'),
  },
  product: {
    list: path(ROOTS_DASHBOARD, '/seller/products'),
    details: path(ROOTS_DASHBOARD, '/seller/product'),
  },
  products: {
    list: path(ROOTS_DASHBOARD, '/products/list '),
  },
};
